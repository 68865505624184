<template>
  <div>
    <mu-appbar color="#555" style="height: 64px; position: fixed; width: 100%;">
      <mu-button icon slot="left" @click="showMenuList">
        <mu-icon value="menu"></mu-icon>
      </mu-button>
      <div style="font-weight: 600; font-size: 18px; margin-right: 30px; text-align: left;">
        路易莎管理後台
      </div>
      <el-button :icon="authView.icon"
        :type="authView.type"
        class="auth" plain round>
        {{ authView.name }}
      </el-button>
    </mu-appbar>
    <!-- 測試部署 -->
    <el-row style="padding-top: 64px;">
      <el-col :xs="col('menu', 'xs')" :sm="col('menu', 'sm')" :md="col('menu', 'md')" :lg="col('menu', 'lg')" :xl="col('menu', 'xl')">
        <el-menu :class="{ noncollapse: windowWidth > 767, collapse: windowWidth <= 767 }"
          @select="getIndex"
          :default-active="index"
          :collapse="false"
          :unique-opened="true"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b"
          class="el-menu-vertical-demo">
          <el-menu-item v-for="(menu, idx) in routeName" :key="menu" :index="`${idx + 1}`">
            <span slot="title">{{ menu }}</span>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col style="position: relative; height: calc(100vh - 64px); overflow: auto;"
        :xs="col('page', 'xs')" :sm="col('page', 'sm')" :md="col('page', 'md')" :lg="col('page', 'lg')" :xl="col('page', 'xl')">
        <router-view></router-view>
        <!-- LOGO -->
        <div style="position: absolute; bottom: 10px; left: 10px; text-align: left;">
          <img src="https://i.imgur.com/SITKieL.png" width="120"/>
          <div style="padding-left: 12px; font-size: 13px;">Copyright © 2020 埃立思科技股份有限公司 版權所有</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapActions, createNamespacedHelpers } from 'vuex';

const emc = createNamespacedHelpers('emc');

export default {
  data() {
    return {
      showMenu: false,
      logoutDia: false,
      index: localStorage.getItem('louisaPage') ? localStorage.getItem('louisaPage') : '1',
      auth: '',
      routes: ['', 'member', 'history', 'register', 'item', 'store', 'shopMenu', 'staff'],
      routeName: ['更新日誌', '會員查詢', '訂單記錄', '註冊會員', '品項資料', '門市資料', '門市菜單狀態', '門市人員設定', '返回主選單'],
      windowWidth: document.body.offsetWidth
    };
  },
  mounted() {
    const pathName = window.document.location.pathname.split('/louisa-admin/')[1];
    if (localStorage.getItem('uid')) {
      if (this.user) {
        this.auth = this.user.Service.client.louisa.Character;
        if (pathName !== this.routes[this.index - 1]) {
          this.switchPage(this.index);
        }
      }
      else {
        this.getUserDetail().then(() => {
          if (this.user) {
            this.auth = this.user.Service.client.louisa.Character;
            if (pathName !== this.routes[this.index - 1]) {
              this.switchPage(this.index);
            }
          }
          else {
            this.$router.push('/');
          }
        })
      }
    }
    else {
      this.$router.push('/');
    }
  },
  computed: {
    ...emc.mapGetters({
      user: 'user'
    }),
    // 更新
    authView() {
      let icon = '';
      let type = 'info';
      let name = '-';
      if (this.auth === 'ADMIN') {
        icon = 'el-icon-user-solid';
        type = 'danger';
        name = '管理者';
      }
      if (this.auth === 'EDITOR') {
        icon = 'el-icon-edit';
        type = 'primary';
        name = '編輯者';
      }
      if (this.auth === 'VIEWER') {
        icon = 'el-icon-view';
        type = 'success';
        name = '檢視者';
      }
      return { icon, type, name };
    },
  },
  methods: {
    ...emc.mapActions({
      getUserDetail: 'getUserDetail'
    }),
    showMenuList() {
      this.showMenu = !this.showMenu;
    },
    switchPage(idx) {
      if (idx !== '9') {
        this.index = idx;
        localStorage.setItem('louisaPage', idx);
        this.$router.push(`/louisa-admin/${this.routes[idx - 1]}`);
      }
      if (idx === '9') {
        localStorage.setItem('louisaPage', '1');
        this.$router.push('/firstPage');
      }
    },
    getIndex(idx) {
      if (idx !== this.index) {
        this.switchPage(idx);
      }
      if (this.windowWidth <= 767) {
        this.showMenu = false;
      }
    },
    col(val, size) {
      if (val === 'menu') {
        if (this.showMenu) {
          if (size === 'xs') {
            return 24;
          }
          return 6;
        }
        else {
          return 0;
        }
      }
      else {
        if (this.showMenu) {
          if (size === 'xs') {
            return 24;
          }
          return 18;
        }
        else {
          return 24;
        }
      }
    },
  }
}
</script>

<style scoped>
  .auth {
    position: absolute;
    height: 40px;
    right: 10px;
    line-height: 40px;
    top: 12px;
    z-index: 10000;
    padding: 0 25px 0 22px;
    font-size: 15px;
    border: 1px solid #fff;
  }
  .checkButton:hover {
    background-color: #555;
    color: #fff;
  }
  .checkButton {
    text-align: center;
    border: 1px solid #888;
    border-radius: 5px;
    padding: 5px 0;
    cursor: pointer;
    transition: 0.3s;
  }
  .collapse {
    text-align: left;
    font-weight: 600;
    overflow: auto;
    margin-right: -1px;
  }
  .noncollapse {
    text-align: left;
    font-weight: 600;
    height: calc(100vh - 64px);
    overflow: auto;
    margin-right: -1px;
  }
</style>